var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"lkh9BAlhGrJdeNXaeTPhO"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import getPublicConfig from '@/utils/getPublicConfig';
import * as Sentry from '@sentry/nextjs';

const publicRuntimeConfig = getPublicConfig();
const { APP_ENV, SENTRY_DSN } = publicRuntimeConfig;

if (APP_ENV !== 'development') {
  Sentry.init({
    environment: APP_ENV,
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.05,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Not Found',
      'Failed to fetch',
    ],
  });
}
